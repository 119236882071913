(function ($, glossaryParser) {
    $(document).ready(function () {
        $.ajax({
                url: "/api/content/glossary/all",
                type: "get",
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json"
            })
            .done(function (data) {
                glossaryParser.glossaryData = data.sort(function (a, b) {
                    return b.Term.length - a.Term.length;
                });
                glossaryParser.glossaryTerms = glossaryParser.glossaryData.map(function (item) {
                    if (item.Synonyms !== '')
                        glossaryParser.addItemToGlossaryWithSynonymsData(item);
                    return item.Term;
                }).join('|');
                glossaryParser.glossarySynonymsTerms = glossaryParser.glossaryWithSynonymsData.map(function (item) {
                    return item.Term;
                }).join('|');

                glossaryParser.glossaryTermsRegExp = glossaryParser.glossaryTerms
                                    ? glossaryParser.createRegExp(glossaryParser.glossaryTerms)
                                    : '';

                glossaryParser.glossarySynonymsRegExp = glossaryParser.glossarySynonymsTerms 
                                        ? glossaryParser.createRegExp(glossaryParser.glossarySynonymsTerms)
                                        : '';

                if (data.length) glossaryParser.glossify();
            });
    });
})(jQuery, new GlossaryParser($, window, document));
