(function ($) {
    $(document).ready(function () {
        //console.log('storypage init');
        function ResizeFrame() {
            console.log("resizing");
            var frameHeight = $(window).height() - $('#mainMenu').height() - $('header.header').height() - 10;
            //console.log(frameHeight);
            document.getElementById('storyframe').height = frameHeight;
        }
        if ($('#storyframe').length > 0) {
            $('#storyframe').on('load', function () { ResizeFrame(); });
            $(window).resize( function () { ResizeFrame(); });
        }
    });
})(jQuery);