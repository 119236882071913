window.patientInformation.bookmark = {};
var b = window.patientInformation.bookmark;
var c = window.patientInformation.constants;

b.toggleBookmark = function toggleBookmark(elm) {
    var elm$ = $(elm);
    var url = "/api/customtable/bookmark/toggle/" + elm$.data("guid") + "/" +
                elm$.data("userguid") + "/" + elm$.data("bookmark");

    $.ajax({
        url: url,
        type: "GET",
        success: function () {
            elm$.toggleClass("is-bookmarked");
            elm$.trigger("bookmark:toggled");
            jQuery.MessageNotify('bookmark', elm$);
        },
        error: function (response) {
            if (response.status === 401) {
                createSubDomainCookie(c.notificationCookieName, 'bookmark|' + elm$.data("title") + '|' + elm$.data("bookmark"));
                window.location.href = "/account/login?bookmark=" + elm$.data("bookmark") + ":" + elm$.data("guid");
            }
            //else {
            //    console.log(response.statusText);
            //    console.log(response.statusCode);
            //    console.log(response);
            //}
        }
    });
};

