(function ($) {
    // add rel='noopener noreferrer' to all valid links
    $(document).ready(function () {
        var nonUrlLinkRe = /(^javascript|^mailto|^tel)/;

        $("a")
        .filter(function (i, el) {
            if (!el.href)
                return false;

            return !nonUrlLinkRe.test(el.href) && el.target === "_blank";
        })
        .prop('rel', 'noopener noreferrer');
    });
})(jQuery);