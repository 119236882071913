(function ($, window, document, undefined) {
    var c = window.patientInformation.constants;
    var h = window.patientInformation.helpers;

    $(".tabs").tabCollapse({
        tabsClass: 'hidden-sm hidden-xs',
        accordionClass: 'visible-sm visible-xs',
        accordionTemplate: function (heading, groupId, parentId, active) {
            return '<div class="panel panel-default">' +
                '   <div class="panel-heading">' +
                '      <div class="panel-title">' +
                '      </div>' +
                '   </div>' +
                '   <div id="' + groupId + '" class="panel-collapse collapse ' + (active ? 'in' : '') + '">' +
                '       <div class="panel-body js-tabcollapse-panel-body">' +
                '       </div>' +
                '   </div>' +
                '</div>'

        }
    });
    $(".tabs").on('show-accordion.bs.tabcollapse', function () {
        $(this).find('.tab').attr('role', 'button').attr('aria-expanded', 'false').attr('tabindex', '0').off('keydown', KeyPressedOnTab);
    }).on('shown-accordion.bs.tabcollapse', function () {
        //issue for initial expanded section, aria-expanded is false it should be true
        $(this).siblings("[id$='-accordion']").find('.in').parents('.panel').find('.tab').attr('aria-expanded', 'true')
    }).on('shown-tabs.bs.tabcollapse', function () {
        $(this).find('.tab').attr('role', 'tab').removeAttr('aria-expanded').attr('tabindex', '-1').on('keydown', KeyPressedOnTab);
        //apply tabindex on selected tab
        var activeElement = $(this).find('li.active');
        if (activeElement.length === 0) {
            $($(this).find('.tab')[0]).attr('tabindex', '0');
        } else {
            $($(activeElement[0]).find('.tab')[0]).attr('tabindex', '0');
        }
    });

    KeyPressedOnTab = function (event) {
        var key = event.keyCode;
        var tabs = $(event.target).parents('.tabs');
        var activeElement = tabs.find('li.active');
        var selectedTab = activeElement.find('.tab');
        var allTabs = tabs.find('.tab');
        var currentTabIndex = 0;
        for (var i = 0; i < allTabs.length; i++) {
            if ($(event.target).attr('href') === $(allTabs[i]).attr('href')) {
                currentTabIndex = i;
            }
        }

        switch (key) {
            case c.keys.end:
                event.preventDefault();
                // Activate last tab
                focusLastTab(allTabs);
                break;
            case c.keys.home:
                event.preventDefault();
                // Activate first tab
                focusFirstTab(allTabs);
                break;
            // Up and down are in keydown
            // because we need to prevent page scroll >:)
            case c.keys.left:
                event.preventDefault();
                focusTab(allTabs, -1, currentTabIndex);
                break;
            case c.keys.right:
                event.preventDefault();
                focusTab(allTabs, 1, currentTabIndex);
                break;
            case c.keys.enter:
                tabs.find('.tab').attr('tabindex', '-1');
                $(event.currentTarget).attr('tabindex', '0');
                break;
            case c.keys.tab:
                if (!event.shiftKey) {
                    event.preventDefault();
                    var tabId = $(selectedTab).attr('href')
                    $(tabId)[0].focus({ preventScroll: true });
                }
                break;
        };
    };

    // Make a guess
    function focusTab(tabs, change, currentTabIndex) {
        var nextTab = currentTabIndex + change;
        if (nextTab === tabs.length) {
            focusFirstTab(tabs);
        } else if (nextTab === -1){
            focusLastTab(tabs)
        } else {
            tabs[nextTab].focus();
        }
    };

    // Make a guess
    function focusFirstTab(tabs) {
        tabs[0].focus();
    };

    // Make a guess
    function focusLastTab(tabs) {
        tabs[tabs.length - 1].focus();
    };

    $(".tab").on('click', function () {
        if ($(this).parents('.detail-page-tab-section.sticky-tabs.affix').length > 0) {
            $('html,body')
                .animate({
                    scrollTop: $($(this).attr('href')).parents('.tab-content').offset().top - 170
                }, 500);
            /*height of sticky container is 170 when title and tabs are sticky*/
        }
    });
    if ($(".tabs").is(':visible')) {
        $(".tabs").trigger('shown-tabs.bs.tabcollapse');
    } else {
        $(".tabs").trigger('show-accordion.bs.tabcollapse');
    }

    $('div[id$=-accordion]').on('shown.bs.collapse', function () {
        var collapsed = $($(this).find('.panel-collapse.collapse.in').parents('.panel.panel-default')[0]);
        if (!h.isElementTopInView(collapsed)) {
            h.scrollIntoView(collapsed, 500);
        }
    });


})(window.jQuery, window, document);