(function ($, window, document, pi) {

    
    $('.delete-bookmark')
        .on('bookmark:toggled', function () {
            var $button = $(this);
            $button.closest('.nsw-list-item').fadeOut(500, function () {
                var container = $(this).closest('.tab-pane');
                $(this).remove();
                var remainingRows = container.find('.nsw-list-item');
                if (remainingRows === undefined || remainingRows.length === 0) {
                    container.find(".my-information-nothing-saved-message-container").show();
                } else {
                    $($(remainingRows[0]).find('a')[0]).focus();
                }
            });
        });

    $('.tab-pane').each(function (index, element) {
        if ($(element).find('.nsw-list-item').length === 0) {
            $(element).find(".my-information-nothing-saved-message-container").show();
        } else {
            $(element).find(".my-information-nothing-saved-message-container").hide();
        }
    });

})(window.jQuery, window, document, window.patientInformation || {});