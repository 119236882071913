$(function () {

    // Hide popup tooltips when user touch the screen on IOS
    $('body').on("touchstart", function(e){
        if(isAppleDevice()){
            $("[data-toggle='popover']").on("shown.bs.popover", function() {
                $('body').css('cursor', 'pointer');
              });

            $("[data-toggle='popover']").on("hide.bs.popover", function() {
                $('body').css('cursor', 'auto');
            });
        }
    });

    window.onload = new function() {  
        // use data-container = body on tablet and mobile version only
        // To fix the flashing popover in iphone. 
        isBreakpoint('xs') || isBreakpoint('sm') 
            ? $('[data-toggle="popover"]').popover() 
            : $('[data-toggle="popover"]').popover({ container: 'body' }); 
    };
});