$(function () {

    window.onload = new function () {
        if ((document.getElementById('custom-popup').style.display) == 'block') {
            $('#bg-opacity').addClass('opacity-active');
        }
    };

    $("#cancel-button-text").on('click', function (e) {
        $('#bg-opacity').removeClass('opacity-active').addClass('opacity-remove');
    });

    $("#close-msg-button").on('click', function (e) {
        $('#bg-opacity').removeClass('opacity-active').addClass('opacity-remove');
    });
});


