function makeTitleSticky() {
    var titleHeight = 0;
    if ($('.sticky-title').length === 0) {
        return 0;
    }
    titleHeight = $('.sticky-title').height();
    var cancerTypeBadge = $(".sticky-title .cancertype-badge");
    var titleOffset;
    if (cancerTypeBadge.length === 0) {
        window.stickyTitleOffsetDelta = 22 + parseInt($('.sticky-title').css('marginBottom').slice(0, -2)) + 10;
        titleOffset = $('.sticky-title').offset().top,
            titleOffsetBottom = $('.footer').outerHeight() + $('.section-cta').outerHeight() + titleHeight + 30;
    }
    else {
        window.stickyTitleOffsetDelta = cancerTypeBadge.height() + parseInt(cancerTypeBadge.css('marginBottom').slice(0, -2)) + 10;
        titleOffset = $('.sticky-title').offset().top + window.stickyTitleOffsetDelta,
            titleOffsetBottom = $('.footer').outerHeight() + $('.section-cta').outerHeight() + titleHeight + 30;
    }   
    
    $('.sticky-title').affix({
        offset: {
            top: titleOffset,
            bottom: titleOffsetBottom
        }
    });
    $('.sticky-title').on('affix.bs.affix', function () {
        $('body').css('paddingTop', titleHeight);
    }).on('affixed-top.bs.affix', function () {
        $('body').css('paddingTop', '0px');
        });
    return titleHeight;
}

function makeTabsSticky(titleHeight) {
    if ($('.sticky-tabs').length === 0) {
        return;
    }
    var tabsHeight = $('.sticky-tabs').height(),
        tabsOffset = $('.sticky-tabs').offset().top - titleHeight - 20 + window.stickyTitleOffsetDelta;
    tabsOffsetBottom = $('.footer').outerHeight() + $('.section-cta').outerHeight()
    $('.sticky-tabs').affix({
        offset: {
            top: tabsOffset,
            bottom: tabsOffsetBottom
        }
    });
    $('.sticky-tabs').on('affix.bs.affix', function () {
        $('.detail-page-tab-content-section').css('paddingTop', tabsHeight + window.stickyTitleOffsetDelta + 10);
    }).on('affixed-top.bs.affix', function () {
        $('.detail-page-tab-content-section').css('paddingTop', '');
    }).on('affix-bottom.bs.affix', function () {
        topPadding = $('body').css('paddingTop');
        $('body').css('paddingTop', topPadding);
    });
}

function handleReize() {
    //1) sticky headers does not work with overflow-x hidden
    //2) sticky headers are only needed on screen >=md
    //3) for smaller devices hamburger menu is enabled 
    //   and it shows extra space on right with overflow-x visible.
    if (isBreakpoint('sm') || isBreakpoint('xs')) {
        $('body').css('overflow-x', 'hidden');
    } else {
        $('body').css('overflow-x', 'visible');
    }
    //Make title and tab sticky on desktop and tablet only
    if (isBreakpoint('lg')) {
        var titleHeight = makeTitleSticky();
        makeTabsSticky(titleHeight);
    }
}
$(window).resize(function () {
    handleReize();
});
handleReize();