(function ($) {

    var notifyHtml = '<div data-notify="container" class="col-xs-11 col-sm-3 alert notification-message hidden-print" role="alert">' +
                        '<div class="message-title-panel">' + 
                            '<span data-notify="icon"></span> ' +
                            '<span data-notify="title" class="message-title">{1}</span> ' +
                        '</div>' +
                        '<div data-notify="message" class="message-body">{2}</div>' +
                        '{3}' +
                    '</div>';

    var settings = {
        placement: { from: "top", align: "right"},
        offset: { x: 0, y: 20},
        spacing: 10,
        z_index: 9999,
        delay: 2000,
        timer: 2000,
        animate: { enter: 'animated fadeInRight', exit: 'animated fadeOutUp' },
        template: notifyHtml
    };                


    // same page notification
    var bookmarkOption = function(tar) {

        var removeAction = tar.hasClass("delete-bookmark") || !tar.hasClass("is-bookmarked");
        var bookmarkType = tar.data("bookmark").charAt(0).toUpperCase() + tar.data("bookmark").substr(1).toLowerCase();
        
        return {
            icon: 'icon user-icon',
            title: bookmarkType + ' ' + (removeAction ? 'removed' : 'saved') ,
            message: '"' + tar.data("title") + '" has been ' + (removeAction ? 'removed from' : 'saved to') + ' <div href="/account/my-saved-pages" class="link">My saved pages</div>',
            url: '<a href="/account/my-saved-pages" data-notify="url" aria-hidden="true"></a>'
        }
    };

    var cancerTypeOption = function(tar) {
        return {
            icon: 'icon disclaimer-icon',
            title: "Cancer type changed",
            message: 'You have changed the cancer type. Page is now loading ' + tar.text() + ' information.',
            url: ' '
        }
    };

    var deleteAccountOption = function () {
        return {
            icon: 'icon user-icon',
            title: "User account Removed",
            message: 'You user account has already removed.',
            url: ' '
        }
    };

    var optionsSelector = function(notifyType, tar) {

        if (notifyType === "bookmark" && tar !== undefined) 
            return bookmarkOption(tar);
        
        if (notifyType === "cancertype")
            return cancerTypeOption(tar);

        if (notifyType === "deleteaccount")
            return deleteAccountOption();
        
        return;
    };

    // show notification after postback

    var bookmarkOptionAfterPostback = function(title, type) {
        
        if (document.location.pathname !== "/account/my-saved-pages")
            return;

        var bookmarkType = type.charAt(0).toUpperCase() + type.substr(1).toLowerCase();

        return {
            icon: 'icon user-icon',
            title: bookmarkType + ' saved' ,
            message: '"' + title + '" has been saved to <a href="/account/my-saved-pages" aria-label="My saved pages">My saved pages</a>',
            url: '<a href="/account/my-saved-pages" data-notify="url" aria-hidden="true"></a>'
        }
    };

    var cancerTypeOptionAfterPostback = function(title) {
        return {
            icon: 'icon disclaimer-icon',
            title: "Cancer type changed",
            message: 'You have changed the cancer type. You are now viewing ' + title + ' information.',
            url: ' '
        }
    };

    var optionsSelectorAfterPostback = function(pinm) {

        pinm = pinm.split("|");
        
        if(pinm.length === 0)
            return;
        
        if(pinm[0] === "bookmark" && pinm.length === 3)
            return bookmarkOptionAfterPostback(pinm[1], pinm[2])

        if(pinm[0] === "cancertype" && pinm.length == 2)
            return cancerTypeOptionAfterPostback(pinm[1]);

        if(pinm[0] === "deleteaccount") 
            return deleteAccountOption();

        return;  
    };

    var MessageNotify = function(notifyType, tar) {
        
        var options;
        var c = window.patientInformation.constants;
        var pinm = readCookie(c.notificationCookieName);

        if(pinm) 
            options = optionsSelectorAfterPostback(pinm);
        else 
            options = optionsSelector(notifyType, tar);

        if (options === undefined) return;

        eraseSubDomainCookie(c.notificationCookieName);

        $.notify(options, settings);
    };

    jQuery.MessageNotify = MessageNotify;

    $(document).ready(function () {
        setTimeout(function(){ 
            jQuery.MessageNotify(); 
        }, 500);
    });

})(jQuery);