window.patientInformation = window.patientInformation || {};
window.patientInformation.constants = window.patientInformation.constants || {};
window.patientInformation.helpers = window.patientInformation.helpers || {};


var c = window.patientInformation.constants;
var h = window.patientInformation.helpers;

h.scrollIntoView = function (selector, delay, offset) {
    $('html,body')
        .animate({
            scrollTop: $('body').scrollTop() + $(selector).offset().top + (offset || 0)
        }, delay);
}

h.isElementInView = function (element, fullyInView) {
    var pageTop = $(window).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).height();

    if (fullyInView === true) {
        return ((pageTop < elementTop) && (pageBottom > elementBottom));
    } else {
        return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
    }
}

h.isElementTopInView = function (element) {
    var pageTop = $(window).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).height();
    return ((elementTop >= pageTop) && (elementBottom >= pageTop));
}