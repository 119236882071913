(function ($, window, document, undefined) {
    'use strict';
    if ($('.glossary-index').length === 0) {
        return;
    }
    var c = window.patientInformation.constants;
    var helpers = window.patientInformation.helpers;
    $('#btnBackToGlossaryTop').click(function () {
        helpers.scrollIntoView('.glossary-terms', 500);
        $('[data-letter]:visible h2').focus();
    });


    var glossLetter = '';
    function showLetter(letter) {
        $('[data-letter]').not('[data-letter=' + letter + ']').hide();
        $('[data-letter=' + letter + ']').show();
        $('.glossary .glossary-index .pagination li.active').removeClass('active');
        $('.glossary .glossary-index .pagination a[data-link-letter=' + letter + ']').parent().addClass('active');
        $('[data-letter]:visible h2').focus();
        return false;
    }

    function firstSetupLetters() {
        $('li.disabled a[data-link-letter]').on('click', function (e) {
            e.preventDefault();
        });
        $('li:not(.disabled) a[data-link-letter]').on('click', function () {
            var letter = $(this).attr('data-link-letter');
            showLetter(letter);
            updateUrl('letter', letter);
            helpers.scrollIntoView('.glossary-terms', 500);
        })

        $('[data-letter] h2').on('keydown', function (event) {
            var key = event.keyCode;
            if (key === c.keys.tab && event.shiftKey) {
                event.preventDefault();
                //user is pressing shift tab
                setTimeout(function () {
                    $('[data-link-letter="a"]').focus();
                }, 100);
            }
        });
    }

    function getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    function updateUrl(parameter, value) {
        var hash = location.hash;
        var queryParameters = {}, queryString = location.search.substring(1),
            re = /([^&=]+)=([^&]*)/g, m;

        while (m = re.exec(queryString)) {
            queryParameters[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
        }
        queryParameters[parameter] = value;
        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + $.param(queryParameters);
            if (hash != '') {
                newurl += hash;
            }
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }
    var letterPar = getParameterByName('letter');
    var hash = window.location.hash.substring(1);

    if (letterPar === '') {
        letterPar = 'a';
    }

    firstSetupLetters();

    if (hash != '') {
        letterPar = hash[0].toLowerCase();
        showLetter(letterPar.toLowerCase());
        helpers.scrollIntoView('#' + hash, 500);
        return;
    }
    showLetter(letterPar.toLowerCase());
})(window.jQuery, window, document);