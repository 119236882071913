$(function () { 
    var c = window.patientInformation.constants;

    var $body = $('body');

    $('.js-open-nav button[data-toggle="slide-collapse"]').on('click', function () {
        $(this).toggleClass('open');
        var $navMenuCont = $($(this).data('target'));
        $navMenuCont.toggleClass('open');
        $body.toggleClass(c.menuOpenCssClass);
        $body.removeClass(c.searchOpenCssClass);

        if ($('.navbar-expand-lg .navbar-collapse').hasClass('open')) {
            $('.navbar-expand-lg .navbar-collapse').attr("aria-hidden", "false");
        }
        else {
            $('.navbar-expand-lg .navbar-collapse').attr("aria-hidden", "true");
        }
        $(".account-body").attr("aria-hidden", "true");
        $(this).attr("aria-hidden", "false");
        $('.navbar-nav .dropdown > a').each(function (i, obj) {
            $(this).find("span").attr("aria-label", $(this).text());
        });
        $('.nav-level-two-primary-menu').each(function (i, obj) {
            $(this).find("span").attr("aria-label", $(this).text());
        });       
        $('.my-account-body-close-btn').click();
    });

    $('.search-button').on('click', function (event) {
        event.preventDefault();
        var isMenuOpen = $('body').hasClass(c.menuOpenCssClass);
        if (isMenuOpen) {
            var $menuButton = $('.search-button + .navbar-toggle');
            $menuButton.click();
        }
        $('.my-account-body-close-btn').click();
        $body.toggleClass(c.searchOpenCssClass);
    });

    $(".btn-search").on("click", function (o) {
        o.preventDefault();
        var searchtext = $('#sitesearch').val();
        window.location.href = "/search?searchmode=allwords&searchtext=" + searchtext;
    });

    $(".btn-search-mobile").on("click", function (o) {
        o.preventDefault();
        var searchtext = $('#sitesearchMobile').val();
        window.location.href = "/search?searchmode=allwords&searchtext=" + searchtext;
    });

    $('#sitesearch').keypress(function (e) {
        o.preventDefault();
        if (e.which == 13) {
            $('.btn-search').click();
            return false;
        }
    });

    $('#sitesearchMobile').keypress(function (e) {
        o.preventDefault();
        if (e.which == 13) {
            $('.btn-search-mobile').click();
            return false;
        }
    });
});