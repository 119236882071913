(function ($) {

    var $columnOne = $(".column-one");
    var $columnTwo = $(".column-two");
    var $columnThree = $(".column-three");

    var OnResize = function() {
        if (typeof(accordionItems) !== "undefined" && accordionItems.length > 0) {
            ClearAllColumns();
            SetRowsOnBreakpoint(accordionItems);
        }     
    };

    var ClearAllColumns = function() {
        $columnOne.empty();
        $columnTwo.empty();
        $columnThree.empty();  
    };

    var SetRowsOnBreakpoint = function(items) {
        if(isBreakpoint('xs') || isBreakpoint('sm')) {
            for(var i in items)
                addRow(items[i], $columnOne);
            return;
        }
            

        if(isBreakpoint('md')) {
            for(var i in items)
                if (i % 2 === 0)
                    addRow(items[i], $columnOne);
                else 
                    addRow(items[i], $columnTwo)
            return;
        }

        if(isBreakpoint('lg')) {
            for(var i in items)
                if (i % 3 === 0)
                    addRow(items[i], $columnOne);
                else if (i % 3 === 1)
                    addRow(items[i], $columnTwo);
                else 
                    addRow(items[i], $columnThree);   
            return;
        }
    };

    var addRow = function(item, targetColumn) {

        if (targetColumn === undefined || targetColumn == null)
            return;

        var row = document.createElement('div');
        row.className = 'row';

        row.innerHTML = createRowInnerHtml(item);
        targetColumn.append(row);
    };

    var createRowInnerHtml = function (item) {
        return '<div class="col-sm-12 summary-card-container">\
                    <div class="panel panel-default summary-card">\
                        <div class="panel-heading collapsed" data-toggle="collapse" data-target="#' + item.Guid + '" tabindex="0" aria-expanded="false">\
                            <span class="sr-only sr-when-collapsed">Click to expand</span>\
                            <div class="panel-title">\
                                <div class="row">\
                                    <div class="col-sm-10 col-xs-10 summary-card-title">' + item.Title + '</div>\
                                    <div class="col-sm-2  col-xs-2"><span class="summary-card-toggle-collapse" aria-hidden="true"></span></div>\
                                </div>\
                            </div>\
                            <div id="' + item.Guid + '" class="panel-collapse collapse">\
                                <div class="panel-body glossary-zone">' + item.Abstract + '</div>\
                                <div class="big-chevron-right">\
                                    <a href="' + item.Path + '"><span class="big-chevron-text">Read more <i class="fa fa-chevron-right"></i></span></a>\
                                </div>\
                            </div>\
                        </div>\
                    </div >\
                </div >'
    };

    var fullDateString = new Date();

    $(window).on('resize', function () {
        OnResize();
    });

    OnResize();
    
})(jQuery);