function GlossaryParser($, window, document) {
    var c = window.patientInformation.constants;
    var tagsToIgnore = ['script', 'noscript', 'style', 'button', 'a', 'input', 'textarea', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        classesToIgnore = ['.no-glossary', '.sr-only', '[data-glossary-id]'],
        glossarySearchZones = ['.glossary-zone'];
    var self = this;

    this.glossaryData = [];
    this.glossaryWithSynonymsData  = [];
    this.glossaryTerms = [];
    this.glossaryTermsRegExp = null;
    this.glossarySynonymsTerms = null;

    var getGlossaryZonesSelector = function () {
        var toBeIgnored = tagsToIgnore.concat(classesToIgnore);

        var ignoredItems = '';

        toBeIgnored.map(function (item) {
            ignoredItems += ':not(' + item + ')';
        });

        return '.glossary-zone' + ' *' + ignoredItems;
    };

    function parseDOMForGlossary(glossaryZones) {

        if (!glossaryZones || !glossaryZones.length) {
            parseGlossaryZone(document);
        }
        else {
            for (var i = 0, count = glossaryZones.length; i < count; i++) {
                parseGlossaryZone(glossaryZones[i]);
            }
        }

    }

    function parseGlossaryZone(glossaryZone) {

        var nodes = Array.prototype.slice.call(glossaryZone.querySelectorAll(getGlossaryZonesSelector()));

        if (!nodes || nodes.length < 1)
            return;

        // parse Glossary Terms
        self.parseGlossaryTermsOrSynonyms(nodes, self.glossaryTermsRegExp, 'glossary-term', true);
        // parse Synonyms Terms
        self.parseGlossaryTermsOrSynonyms(nodes, self.glossarySynonymsRegExp, 'glossary-term glossary-synonyms-term', false);
    }

    this.parseGlossaryTermsOrSynonyms = function (nodes, regExpTerms, wrapClass, noRepeatTerms) {
        var foundTerms = [];

        if (regExpTerms === '')
            return;

        for (var j = 0, nodesCount = nodes.length; j < nodesCount; j++) {
            
            var textNode = nodes[j],
                hasMatch = regExpTerms.test(textNode.innerText);

            if (hasMatch) {
                var matchedResults = textNode.innerText.match(regExpTerms);

                for (var k = 0, count = matchedResults.length; k < count; k++) {
                    var term = matchedResults[k].toLowerCase();

                    if (noRepeatTerms && self.isIgnoreTerm(foundTerms, term)) {
                        continue;
                    }

                    var regExpTerm = self.createRegExp(term, noRepeatTerms);

                    findAndReplaceDOMText(textNode, {
                        find: regExpTerm,
                        wrap: 'a',
                        wrapClass: wrapClass,
                        filterElements: function (elem) {

                            if (typeof elem === 'undefined' || typeof elem.tagName === 'undefined') {
                                return false;
                            }

                            if (typeof elem.className !== 'undefined'
                                && typeof elem.className === 'string'
                                && elem.className.indexOf('glossary-term') >= 0
                                && elem.className.indexOf('glossary-synonyms-term') >= 0) {
                                return false;
                            }

                            if (typeof elem.parentElement !== 'undefined'
                                && typeof elem.parentElement.className === 'string'
                                && elem.parentElement.className.indexOf('glossary-term') >= 0
                                && elem.className.indexOf('glossary-synonyms-term') >= 0) {
                                return false;
                            }

                            if (tagsToIgnore.indexOf(elem.tagName.toLowerCase()) >= 0) {
                                return false;
                            }

                            return true;
                        }
                    });

                    foundTerms.push(term);
                    
                }
            }
        }
    }

    function removeClassFromElementsToBeIgnored() {
        var ignoreQuery = classesToIgnore.map(function (className) {
            return className + ' .glossary-term';
        })
            .join(',');

        $(ignoreQuery).removeClass('glossary-term');
    }

    function addDefinitions() {
        
        // add Glossary Terms Definition
        self.addGlossaryDefinitions($(".glossary-term:not(.glossary-synonyms-term)"), self.glossaryData);
        // add Synonyms Definition
        self.addGlossaryDefinitions($(".glossary-synonyms-term"), self.glossaryWithSynonymsData);
    }

    this.addGlossaryDefinitions = function (glossaryTerm, globalData) {        
        for (var j = 0; j < glossaryTerm.length; j++) {
            for (var i = 0; i < globalData.length; i++) {
                if (globalData[i].Term.toLowerCase() === glossaryTerm[j].innerText.toLowerCase()) {
                    var $element = $(glossaryTerm[j]);
                    var terms = globalData[i].ParentTerm ? globalData[i].ParentTerm : globalData[i].Term;
                    $element.attr('data-glossary-term', terms);
                    $element.attr('href', c.glossaryPageLink + '#term-' + terms.toLowerCase().replace(c.glossaryIdFormatter, '-'));
                    $element.attr('data-glossary-definition', globalData[i].Definition);
                }
            }
        }
    }

    function addExplicitDefinitions() {
        $("span[data-glossary-id]")
            .each
            (
            function () {
                var this$ = $(this);
                var id = parseInt(this$.data("glossary-id"));
                var ix;

                for (ix = 0; ix < self.glossaryData.length; ix++)
                    if (self.glossaryData[ix].Id === id)
                        break;

                if (self.glossaryData.length > 0 && ix < self.glossaryData.length) {
                    this$.addClass("glossary-term");
                    this$.attr('data-glossary-term', self.glossaryData[ix].Term);
                  this$.attr('href', c.glossaryPageLink + '#term-' + self.glossaryData[ix].Term.toLowerCase().replace(c.glossaryIdFormatter, '-'));
                    this$.attr('data-glossary-definition', self.glossaryData[ix].Definition);
                }
            }
            );
    }

    function addRoleLink() {
        $('.glossary-term').attr({
            'role': 'link',
            'tabindex': '0'
        });
    }

    function addQtip() {
        $('.glossary-term').qtip({
            show: {
                event: 'click'
            },
            hide: {
                event: 'unfocus click'
            },
            style: {
                classes: 'qtip-custom-styles qtip-rounded qtip-shadow'
            }
        });
    }

    function addPopOver() {
        $('.glossary-term').each(function () {
            var $this = $(this);
            var term = $this.attr('data-glossary-term');
            var definition = $this.attr('data-glossary-definition');
            var trigger = isMobile() ? 'hover click' : 'hover focus';
            $this.attr({
                'data-trigger': trigger,
                'tabindex': '0',
                'data-toggle': 'popover',
                'data-placement': 'top',
                'data-content': definition
            });
            $this.popover();
        });
    }

    this.escapeSpecialCharacters = function(string) {
        return string.replace(new RegExp("[;\\\/:*?\<>&\(\)']", 'ig'), "\\$&");
    };

    this.createRegExp = function (terms, noGlobalFlag) {
        return new RegExp('\\b(' + self.escapeSpecialCharacters(terms.trim()) + ')(?=\\s|\\.|\\?|\\!|\\,|\\\'|\\)|\\"|\\-|\\:|\\;|\\]|\\}|$)', noGlobalFlag ? 'i' : 'ig')
    }
    
    this.addItemToGlossaryWithSynonymsData = function (item) {
        item.Synonyms.split('|').forEach(function(synonyms) {
            self.glossaryWithSynonymsData.push({
                Term: synonyms.toLowerCase(),
                ParentTerm: item.Term,
                Definition: item.Definition
            });
        });
    }

    this.isIgnoreTerm = function (foundTerms, term) {
        return self.createRegExp(term).test(foundTerms.join(' | '));
    } 

    this.glossify = function() {

        // Target on content-blocks class to prevent glossary missing on mobile due to switching from tab to accordion
        var zones = $(".content-blocks");

        parseDOMForGlossary(zones);

        removeClassFromElementsToBeIgnored();

        addDefinitions();

        addExplicitDefinitions();

        addRoleLink();

        //addQtip();

        addPopOver();
    };

}

if (typeof(module) !== "undefined") module.exports = GlossaryParser;
