$(function () {
    var menuOffsetFromBodyTop = 75;
    $('.btn-group-section-selector .btn').on('click', function () {
        var this$ = $(this);
        var menu$ = this$.parent().find('ul.dropdown-menu');
        menu$.css('height', 'auto');
        var height = parseInt(menu$.css('height').slice(0, -2));
        var bodyHeight = parseInt($('body').css('height').slice(0, -2));
        if (bodyHeight < (height + menuOffsetFromBodyTop)) {
            menu$.css('overflow-y', 'auto');
            menu$.css('height', (bodyHeight - menuOffsetFromBodyTop - 15)+'px');
        } else {
            menu$.css('overflow-y', 'initial');
            menu$.css('height', 'auto');
        }
    })
});