$(function () {
    var c = window.patientInformation.constants;
    var $body = $('body');

    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            $body.addClass(c.userIsTabbingClass);
            $(window).off('keydown', handleFirstTab);
            $(window).on('mousedown', handleFirstClick);
        }
    }

    function handleFirstClick(e) {
        $body.removeClass(c.userIsTabbingClass);
        $(window).off('mousedown', handleFirstClick);
        $(window).on('keydown', handleFirstTab);
    }

    $(window).on('keydown', handleFirstTab);
});