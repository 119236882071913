// SRDescription function -- timeout in ms
function SRDescription(message, timeout) {
    if ($(".sr-message")) {
        var time = timeout ? timeout : 0;
        setTimeout(function () {
            $(".sr-message").text(message)
        }, timeout)

        CleanSRDescription();
    }
}

function CleanSRDescription() {
    $(".sr-message") ? $(".sr-message").text("") : null;
}