$(function () {
    var c = window.patientInformation.constants;

    $('a[data-cancertype]').click(function (event) {

        event.preventDefault();
        var selectedCancerType = $(this).attr("data-cancertype");
        if (selectedCancerType === '/') {
            eraseSubDomainCookie(c.cancerTypeCookieName);
        } else {
            createSubDomainCookie(c.cancerTypeCookieName, selectedCancerType);
        }
        createSubDomainCookie(c.notificationCookieName, 'cancertype|' + $(this).text());

        var currentUrl = document.querySelector("link[rel='canonical']").getAttribute("href");
        var uriPos = currentUrl.split("/", 4).join("/").length;
        document.location.href = "/" + selectedCancerType + currentUrl.substr(uriPos);;
    });

    var cancerTypeFromQuery = readQueryParam('cancertype');
    if (cancerTypeFromQuery) {
        eraseSubDomainCookie(c.cancerTypeCookieName);
        createSubDomainCookie(c.cancerTypeCookieName, '/' + cancerTypeFromQuery);
    }
});