(function()
{
	$(function()
	{
		$("[id ^= 'checklistModal-'] .nsw-button.print").click(function()
		{
			print();
		});
	});
})();
