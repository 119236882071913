(function () {
    'use strict';

    $("#print-selection-button").on('click', function (e) {
        $(".print-toggle").each(function () {
            var printsection = $('#' + $(this).attr("value"));
            if ($(this).is(':checked')) {
                printsection.removeClass('d-print-none');
            } else {
                printsection.addClass('d-print-none');
            }
        });

        $('#printSectionsModal').modal('hide');

        // Delay printing until modal has disappeared
        setTimeout(function () {
            window.print();
        }, 500);
    });

    $("#print-all-button").on('click', function (e) {
        window.print();
    });

    return {}
}).call(this);