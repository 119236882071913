$(function () {
    var c = window.patientInformation.constants;

    var $body = $('body');
    var $wrap = $('.navbar .navwrap');

    // Set the margin on small iphone to avoid iphone bottom tool bar
    var SetOursiteMobileStyle = function () {
        if (isNSmalliphone())
            $(".oursites .dropup").addClass("isSmalliphone");
        else 
            $(".oursites .dropup").removeClass("isSmalliphone");
    };

    // check small screen iphone iPhone 6, 6s, 7 or 8
    var isNSmalliphone = function () {
        if ((window.screen.height / window.screen.width == 667 / 375) && (window.devicePixelRatio == 2)) {
            return true;
        }
    }

    var ActiveLevelOne = function () {
        var tar = $(".navbar.navbar-expand-lg .navbar-collapse");
        if (tar.length > 0) {

            $(tar).find(".nav > li").each(function () {
                if ($(this).find(".level2-menu > li.active").length > 0)
                    $(this).addClass("active");
            });
        }
    };

    var SetMobileMenuStyle = function () {
        $('.action-nav .navbar-toggle').on('click', function () {
            ActiveLevelOne();
            if (!$body.hasClass(c.menuOpenCssClass)) {
                //$('.navbar').removeClass('hidden-menu').css('display', 'block');

                SetOursiteMobileStyle();

                $('.navbar-collapse .oursites button').on('click', function () {
                    $wrap.css('position', 'relative');
                });
            }
        });
    };

    var OursitesWCAGFocus = function () {
        $(".navbar-collapse .dropup ul li:last a").on('keydown focus', function (e) {
            var code = e.keyCode || e.which;

            if (code === 9) {
                OursitecloseDropDown(e);
                return;
            }  
        });
    };

    var OursitesWCAGExpandAndCollaspe = function () {
        if (isBreakpoint('xs') || isBreakpoint('sm')) {
            $(".oursites button").on('click', function () {
                if ($(this).attr("aria-expanded") === "true")
                    helpers.SRDescription("collaspe");
                else
                    helpers.SRDescription("expanded");
            });
        }
    };

    var OursitecloseDropDown = function (e) {
        e.preventDefault();
        $(".oursites button").focus();
        $(".oursites button").dropdown("toggle");
    }

    var init = function (e) {
        SetMobileMenuStyle();
        OursitesWCAGFocus();
        OursitesWCAGExpandAndCollaspe();
        $('#mainMenu .navwrap .navbar-nav .dropdown-menu .dropdown-menu li:nth-of-type(13)').parent().addClass('columns');

        //bootstrap navigation menu dropdown fix for mobile devices   
        $(document).on('click', '.navbar-nav .dropdown > a', function (e) {
            var target = $(e.target);
            if (target.is("span")) {
                e.preventDefault();
            }
            if ($(this).attr("aria-expanded") == "false") {
                $(this).find("span").attr("aria-expanded", "true");
            }
            else {
                $(this).find("span").attr("aria-expanded", "false")
            }
        });

        $(document).on('click', '.nav-level-two-primary-menu > span', function (e) {
            e.preventDefault();
            var target = $(e.target);           
            if ($('ul.nav-level-three:visible').length == 1) {
                target.closest("li").removeClass("open");
                $('ul.nav-level-three').css("display", "none");
                e.stopPropagation();
            }           
        });        
    };

    $(window).resize(function () {
        if ($('.action-nav .navbar-toggle'))
            SetOursiteMobileStyle();    
    });
    
    init();
});
