$(function () {
    $('.summary-card').matchHeight();
    $('.summary-cards-menu .card-item').matchHeight({ byRow:false });
    $('.summary-cards-collapsible').on('click', '.summary-card .panel-heading a', function (e) {
        e.stopPropagation();
    }).on('click', '.summary-card .panel-heading', function () {
        $(this).parents('.summary-card').toggleClass("is-expanded");
        $(this).attr('aria-labelledby', $(this).attr('data-target').substring(1));

        if (!$(this).hasClass('collapsed')) {
            $(this).removeAttr('aria-labelledby');
            return;
        }
        var expanded = $('.summary-cards-collapsible .summary-card .panel-heading:not(.collapsed)')
        expanded.trigger('click');
    }).on('keypress', '.summary-card .panel-heading', function (e) {
        if (e.which === 13) {
            $(this).trigger('click');
        }
    });

    var resizeTimer;

    var resizeFunction = function () {
        $('.cards').each(function(i) {
            itemcount = $(this).children('.card').length,
            colcount = 2,
            lastrow = itemcount % colcount;
            
            if ( $(this).hasClass('cards4') && $(window).width() >= 992 ) {
                colcount = 4;
                lastrow = itemcount % colcount;
            } else if ( $(this).hasClass('cards3') && $(window).width() >= 992 ) {
                colcount = 3;
                lastrow = itemcount % colcount;
            }
            if (lastrow == 1) {
                $(this).find('.card:nth-last-child(1)').css({'margin-right':'auto','margin-left':'auto'});
            } else if (lastrow > 1) {
                $(this).find('.card:nth-last-child(' + parseInt(lastrow) + ')').css({'margin-right':'16px','margin-left':'auto'});
                $(this).find('.card:nth-last-child(1)').css({'margin-right':'auto','margin-left':'16px'});
            }
        });
    };

    $(window).resize(function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeFunction, 100);
    });

    resizeFunction();
});