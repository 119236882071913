(function ($, window, document, pi) {
    pi.bookmarkAddBookmarkSRText = "Click to add bookmark";
    pi.bookmarkRemoveBookmarkSRText = "Click to remove bookmark";
    pi.bookmarkDeleteBookmarkSRText = "Click to delete bookmark";


    $('.bookmark-button')
        .append($("<span class='sr-only'>" + pi.bookmarkAddBookmarkSRText + "<span>"))
        .on('bookmark:toggled', function () {
            var this$ = $(this);
            var element = this;
            if (this$.hasClass('is-bookmarked')) {
                this$.find('span.sr-only').text(pi.bookmarkRemoveBookmarkSRText);
                this$.find('span.material-icons').text('star');
            } else {
                this$.find('span.sr-only').text(pi.bookmarkAddBookmarkSRText);
                this$.find('span.material-icons').text('star_border');
            }

            var allButtonsWithSameGuid = $('a[data-guid="' + this$.attr('data-guid') + '"]');
            for (var i = 0; i < allButtonsWithSameGuid.length; i++) {
                if (!$(allButtonsWithSameGuid[i]).is(this$)) {
                    $(allButtonsWithSameGuid[i]).toggleClass('is-bookmarked');
                    if ($(allButtonsWithSameGuid[i]).hasClass('is-bookmarked')) {
                        $(allButtonsWithSameGuid[i]).find('span.sr-only').text(pi.bookmarkRemoveBookmarkSRText);
                        $(allButtonsWithSameGuid[i]).find('span.material-icons').text('star');
                     } else {
                        $(allButtonsWithSameGuid[i]).find('span.sr-only').text(pi.bookmarkAddBookmarkSRText);
                        $(allButtonsWithSameGuid[i]).find('span.material-icons').text('star_border');
                    }
                }
            }
        })
        .attr('role', 'button');
    
    $('.bookmark-button.is-bookmarked span.sr-only').text(pi.bookmarkRemoveBookmarkSRText);
    $('.bookmark-button.is-bookmarked span.material-icons').text('star');

    $('.delete-bookmark')
        .append($("<span class='sr-only'>" + pi.bookmarkDeleteBookmarkSRText + "<span>"))
        .attr('role', 'button');

})(window.jQuery, window, document, window.patientInformation || {});