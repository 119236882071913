window.patientInformation = window.patientInformation || {};
window.patientInformation.constants = window.patientInformation.constants || {};

var c = window.patientInformation.constants;

c.searchOpenCssClass = 'search-open';
c.menuOpenCssClass = 'menu-open';
c.notificationCookieName = 'pinm';
c.cancerTypeCookieName = 'pict'
c.keys = {
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    delete: 46,
    enter: 13,
    tab: 9,
    space: 32
};
c.userIsTabbingClass = 'tabbing';
c.glossaryPageLink = '/glossary';
c.glossaryIdFormatter = /[(,), , ]/g;